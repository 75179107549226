<template>
    <CollectingDataPage />
</template>
<script>
import CollectingDataPage from '@/components/lnd/collecting-data/CollectingDataPage.vue'

export default {
    name: 'CollectingData',
    components: {
        CollectingDataPage
    }
}
</script>