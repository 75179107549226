<template>
    <div
        class="collecting-data-page"
        :class="{
            'collecting-data-page--loading': isPageLoading,
        }"
    >
        <template v-if="isPageLoading">
            <div class="collecting-data-page__loader">
                <spinner />
            </div>
        </template>

        <div v-else class="collecting-data-page__inner">
            <h1 class="text-center collecting-data-page__header">{{ $t('Application for participation in Presale') }}</h1>


            <div class="collecting-data-page__content">
                <div
                    class="collecting-data-page__form"
                    :class="{ 'collecting-data-page__form--visible': !isFormHidden }"
                >
                    <p class="text-center collecting-data-page__info-message">
                        {{ $t('Make sure your details are correct and include the Email address of the distributor, DexID and the BEP-20 address that is involved in the presale.') }}
                    </p>

                    <div class="form">
                        <label class="form__label">
                            <span class="">Email</span>

                            <input
                                v-model="email"
                                type="email"
                                class="form__input"
                                :placeholder="$t('Email').toString()"
                                :class="{'form-input-error': isEmailError }"
                            >

                            <p
                                class="form-error"
                                :class="{ 'form-error--visible': isEmailError }"
                            >
                                {{ emailError }}
                            </p>
                        </label>

                        <label class="form__label">
                            <span class="">{{ $t('DexID Promocode') }}</span>

                            <input
                                v-model="promocode"
                                type="text"
                                class="form__input"
                                :class="{'form-input-error': isPromocodeError }"
                                :placeholder="$t('Promocode').toString()"
                            >

                            <p
                                class="form-error"
                                :class="{ 'form-error--visible': isPromocodeError }"
                            >
                                {{ promocodeError }}
                            </p>
                        </label>

                        <label class="form__label">
                            <span class="">{{ $t('BEP20 Address') }}</span>

                            <input
                                v-model="address"
                                type="text"
                                class="form__input"
                                :class="{'form-input-error': isAddressError }"
                                :placeholder="$t('BEP20 Address').toString()"
                            >

                            <p
                                class="form-error"
                                :class="{ 'form-error--visible': isAddressError }"
                            >
                                {{ addressError }}
                            </p>
                        </label>

                        <p class="text-center collecting-data-page__info-message">
                            {{ $t('Important: Use only this BEP-20 address for NFT purchases and participation in sales rounds. Airdrop will only be available to this address. Once submitted, this address cannot be changed.') }}
                        </p>

                        <button
                            class="button form-login__button"
                            type="submit"
                            :disabled="isSubmitting"
                            @click="handleSubmit"
                        >
                            <span>
                                {{ $t('Submit') }}

                                <spinner v-if="isSubmitting" small />
                            </span>
                        </button>
                    </div>
                </div>

                <div
                    class="collecting-data-page__list"
                    :class="{
                        'collecting-data-page__list--visible': !isListHidden,
                        'collecting-data-page__list--static': isListStatic
                    }"
                >
                    <p class="collecting-data-page__info-message font-unbounded"><b>{{ $t('Congratulations! Your application has been accepted!') }}</b></p>
                    <p class="collecting-data-page__info-message collecting-data-page__info-message--unset-margin">{{ $t('You have gained access to allocation on Presale!') }}</p>
                    <p class="collecting-data-page__info-message collecting-data-page__info-message--unset-margin">{{ $t('Important: One node is one unique promo code, entitling you to purchase coins on our Launchpad.') }}</p>
                    <p class="collecting-data-page__info-message collecting-data-page__info-message--unset-margin">{{ $t("What's next?") }}</p>
                    <p class="collecting-data-page__info-message collecting-data-page__info-message--unset-margin">{{ $t('Follow the next steps in our') }}&nbsp;<a href="https://t.me/dexnet">{{ $t('Telegram-channel') }}</a>&nbsp;{{ $t("so you don't miss important information.") }}</p>
                    <p class="collecting-data-page__info-message collecting-data-page__info-message--unset-margin">{{ $t('The most interesting will start soon.') }}</p>

                    <p class="collecting-data-page__info-message collecting-data-page__info-message--success">{{ $t('Make sure that all device promo codes are specified. If your promo code is not in this list, use the \"Add promo code\" button and then click \"Save\".') }}</p>

                    <ul>
                        <template v-for="(item, index) in listData">
                            <li
                                class="text-center collecting-data-page__list-item"
                                :key="index"
                            >
                                {{ item }}
                            </li>
                        </template>
                    </ul>

                    <template v-if="isAddingPromocode">
                        <div class="form collecting-data-page__add-device-form">
                            <label class="form__label">
                                <input
                                    v-model="addingPromocode"
                                    type="text"
                                    class="form__input"
                                    :placeholder="$t('Promo code').toString()"
                                    :class="{'form-input-error': isPromocodeError }"
                                >

                                <p
                                    class="form-error"
                                    :class="{ 'form-error--visible': isPromocodeError }"
                                >
                                    {{ promocodeError }}
                                </p>
                            </label>
                        </div>
                    </template>

                    <template v-if="!isAddingPromocode">
                        <button
                            class="button form-login__button"
                            type="submit"
                            @click="handleAddPromocode"
                        >
                            <span>
                                {{ $t('Add promo code') }}
                            </span>
                        </button>
                    </template>

                    <template v-else>
                        <button
                            class="button button--secondary form-login__button"
                            type="submit"
                            :disabled="isAddingPromocodeLoading"
                            @click="handleCancelAddPromocode"
                        >
                            {{ $t('Cancel') }}
                        </button>

                        <button
                            class="button form-login__button"
                            type="submit"
                            :disabled="isAddingPromocodeLoading"
                            @click="handleSavePromocode"
                        >
                            <span>
                                {{ $t('Save') }}

                                <spinner v-if="isAddingPromocodeLoading" small />
                            </span>
                        </button>
                    </template>
                </div>
            </div>
        </div>
        <LndScripts />
    </div>
</template>
<script>
import anime from 'animejs'

import Spinner from '@/components/Spinner.vue'
import LndScripts from '../Scripts.vue'

import { api } from '@/api'

export default {
    name: 'CollectingDataPage',

    components: {
        Spinner,
        LndScripts,
    },

    data: () => ({
        isPageLoading: true,

        isSubmitting: false,
        isFormHidden: false,

        isFormTouched: false,
        isEmailError: false,
        emailError: '',
        isPromocodeError: false,
        promocodeError: '',
        isAddressError: false,
        addressError: '',

        email: '',
        promocode: '',
        address: '',
        addingPromocode: '',

        isListHidden: true,
        isListStatic: false,
        listData: [],

        isAddingPromocode: false,
        isAddingPromocodeLoading: false,
    }),

    methods: {
        async handleSubmit() {
            this.isEmailError = false
            this.isPromocodeError = false
            this.isAddressError = false

            this.emailError = ''
            this.promocodeError = ''
            this.addressError = ''


            this.isSubmitting = true
            this.isFormTouched = true

            if (!this.email) {
                this.isEmailError = true
                this.emailError = this.$t('Required').toString()
            }

            if (!this.promocode) {
                this.isPromocodeError = true
                this.promocodeError = this.$t('Required').toString()
            }

            if (!this.address) {
                this.isAddressError = true
                this.addressError = this.$t('Required').toString()
            }


            // Create request
            try {
                const result = await api.blockchainPresaleRegister({
                    email: this.email,
                    dexid: this.promocode,
                    address: this.address,
                })


                this.listData = result.data.promocodes
                this.isFormHidden = true

                setTimeout(() => {
                    this.isListHidden = false

                    setTimeout(() => {
                        this.isListStatic = true
                    }, 200)
                }, 1000)
            } catch(error) {
                switch(error.response.data.error) {
                    case 'INVALID_DATA':
                        this.isEmailError = true
                        this.emailError = this.$t('Data is invalid').toString()
                        this.isPromocodeError = true
                        this.promocodeError = this.$t('Data is invalid').toString()
                        break;
                    case 'CODE_ALREADY_USED':
                        this.isAddressError = true
                        this.addressError = this.$t('BEP20 Address already in use').toString()
                        break;
                    case 'INVALID_ADDRESS':
                        this.isAddressError = true
                        this.addressError = `${this.$t('You have entered an incorrect Bep-20 address.').toString()} ${this.$t('The request has already been created with a different address.')}`
                        break;
                    case 'ALREADY_REGISTERED':
                        this.isEmailError = true;
                        this.emailError = `${this.$t('The address is already in use by another user.')}`
                        break;
                    case 'INVALID_CODE':
                        this.isPromocodeError = true
                        this.promocodeError = this.$t('Promo code is invalid').toString()
                        break;
                    default:
                        this.isEmailError = true
                        this.isAddressError = true
                        this.isPromocodeError = true

                        this.promocodeError = this.$t('Data is invalid').toString()
                        break;
                }

                this.isSubmitting = false
            }
        },

        handleAddPromocode() {
            this.isPromocodeError = false;
            this.promocodeError = '';

            this.isAddingPromocode = true;
        },

        handleCancelAddPromocode() {
            this.isAddingPromocode = false;
        },

        async handleSavePromocode() {
            this.isAddingPromocodeLoading = true;
            this.isPromocodeError = false;
            this.promocodeError = '';


            if (!this.addingPromocode) {
                this.isPromocodeError = true;
                this.promocodeError = this.$t('Required').toString()
                this.isAddingPromocodeLoading = false;

                return;
            }

            try {
                const result = await api.blockchainPresaleRegister({
                    email: this.email,
                    dexid: this.promocode,
                    address: this.address,
                    code: this.addingPromocode,
                })

                this.listData = result.data.promocodes

                this.isAddingPromocode = false
                this.isAddingPromocodeLoading = false
                this.addingPromocode = ''
            } catch(error) {
                console.error(error);

                this.isPromocodeError = true
                this.promocodeError = this.$t('Promo code is invalid').toString()

                this.isAddingPromocodeLoading = false;
            }
        },

        checkIsLoggedIn() {
            return new Promise((resolve) => {
                api.getAccount(this.$store.state.main.token)
                    .then((result) => result && resolve(true) || resolve(false))
                    .catch(() => resolve(false))
            })
        },
    },

    mounted() {
        anime({
            targets: '.collecting-data-page',
            background: [
                `
                    linear-gradient(180deg, rgba(0, 0, 0, 0.00) 72%, rgba(0, 0, 0, 0.50) 100%),
                    linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%),
                    radial-gradient(36.91% 47.32% at 19.44% 61.35%, rgba(103, 255, 237, 0.25) 0%, rgba(103, 255, 237, 0.00) 100%),
                    radial-gradient(42.38% 54.33% at 81.91% 56.4%, rgba(101, 107, 255, 0.30) 0%, rgba(101, 107, 255, 0.00) 100%),
                    radial-gradient(29.88% 38.31% at 50% 72.42%, rgba(49, 206, 255, 0.25) 0%, rgba(49, 206, 255, 0.00) 100%)
                `,
                `
                    linear-gradient(180deg, rgba(0, 0, 0, 0.00) 72%, rgba(0, 0, 0, 0.50) 100%),
                    linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%),
                    radial-gradient(36.91% 47.32% at 19.44% 61.35%, rgba(101, 107, 255, 0.30) 0%, rgba(101, 107, 255, 0.00) 100%),
                    radial-gradient(42.38% 54.33% at 81.91% 56.4%, rgba(103, 255, 237, 0.25) 0%, rgba(103, 255, 237, 0.00) 100%),
                    radial-gradient(29.88% 38.31% at 50% 72.42%, rgba(49, 206, 255, 0.00) 0%, rgba(49, 206, 255, 0.00) 100%)
                `,
            ],
            easing: 'easeInOutQuad',
            duration: 3000,
            loop: true,
            direction: 'alternate'
        });

        this.checkIsLoggedIn()
            .then((isLoggedIn) => {
                if (isLoggedIn) {
                    this.isPageLoading = false;
                } else {
                    this.$router.push({ path: '/' })
                    this.$toast.error('', {
                        message: this.$t('Authorize firstly').toString(),
                        position: 'top-right',
                        duration: 3000,
                    });
                }
            })
    }
}
</script>
<style lang="scss">

body {
    background: #000;
    margin: 0;
    padding: 0;
}

.text-center {
    text-align: center;
}

.font-unbounded {
    font-family: "Unbounded", sans-serif;
}

.collecting-data-page {
    width: 100%;
    height: 100svh;
    padding: 16px;

    & h1, h2, h3, h4, h5, h6 {
        font-family: "Unbounded", sans-serif;
        color: #daf1f0;
    }

    & .button--secondary {
        background-color: rgba(126, 206, 202, 0.5) !important;
    }

    // & p {
    //     color: #daf1f0;
    // }

    &--loading {
        overflow: hidden;
    }

    &__loader {
        width: 100%;
        height: 100svh;

        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__inner {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    &__header {
        font-size: 36px;
        font-weight: 600;
    }

    &__info-message {
        font-size: 14px;
        font-weight: 400;
        margin: 20px 0;
        text-align: center;
        max-width: 500px;

        color: #daf1f0;

        &--success {
            color: #00d5c8;
        }

        &--unset-margin {
            margin: 12px 0;
        }

        & a {
            color: #00d5c8;
            font-weight: 500;

            &:visited {
                color: #00d5c8;
            }
        }
    }

    &__content {
        max-width: 500px;
        min-height: 650px;
        overflow: visible;
        width: 100%;
        margin-top: 24px;
        overflow: hidden;

        position: relative;

        & .form,
        & ul,
        .button {
            max-width: 350px !important;
            margin-left: auto !important;
            margin-right: auto !important;
            width: 100% !important;
        }
    }

    &__form {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;

        transition: all 0.3s ease;
        transform: translateY(-25%);
        opacity: 0;
        z-index: 0;

        &--visible {
            transform: translateY(0);
            opacity: 1;
            z-index: 1;
        }

        & .form {
            position: unset !important;
            max-width: unset;
            max-height: unset;
            width: 100%;
            transform: unset;
            opacity: 1;
        }

        & .form-error {
            font-size: 11px;
            opacity: 0;
            transform: translateY(10px);
            transition: opacity 0.3s, transform 0.3s;

            &--visible {
                opacity: 1;
                transform: translateY(0);
            }
        }

        & .form__label + .form__label {
            margin-top: 4px;
        }

        & .form__label span {
            font-size: 14px;
            color: #daf1f0;
            display: inline-block;
            margin-bottom: 4px;
        }

        & .form-error {
            min-height: 13px;
        }
    }

    &__list {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;

        transition: all 0.3s ease;
        transform: translateY(-25%);
        opacity: 0;
        z-index: 0;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        ul {
            list-style: none;
            padding: 0;
            margin: 0;

            max-height: 145px;
            width: 100%;
            height: 100%;
            overflow: auto;
        }

        &--visible {
            transform: translateY(0);
            opacity: 1;
            z-index: 1;
        }

        &--static {
            position: static;
            transform: unset;
            opacity: 1;
            z-index: 1;
        }

        &-item + &-item {
            margin-top: 8px;
        }

        &-item {
            font-size: 14px;
            color: #daf1f0;

            background-color: rgba(126, 206, 202, 0.1);
            border-radius: 10px;
            width: 100%;
            padding: 13px;

            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    &__add-device-form {
        margin-top: 20px;
    }
}

@media (max-width: 760px) {
    .collecting-data-page {
        height: calc(70px + 230px + 750px);

        &__header {
            font-size: 28px;
        }

        &__content {
            min-height: 750px;
        }

        &__list {
            ul {
                max-height: 145px;
            }
        }
    }
}

@media (max-width: 500px) {
    .collecting-data-page {
        // height: 150svh;
        // padding-bottom: 120px;

        &__header {
            font-size: 28px;
            // margin-top: 50%;
        }
    }
}
</style>